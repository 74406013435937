import { useState, useEffect } from "react";
import throttle from 'lodash.throttle'

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState({ x: null, y: null });

  const updateScrollPosition = throttle(e => {
    setScrollPosition({ 
      x: window.pageXOffset || document.documentElement.scrollLeft, 
      y: window.pageYOffset || document.documentElement.scrollTop 
    });
  }, 300);
  
  useEffect(() => {
    window.addEventListener("scroll", updateScrollPosition);

    return () => window.removeEventListener("scroll", updateScrollPosition);
  }, [updateScrollPosition]);

  return { scrollPosition };
};

export default useScrollPosition;