/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql, StaticQuery } from "gatsby"

const SEO = ({ description, title, pageTheme, image }) => {
  
  useEffect(() => {
    if (pageTheme) { 
      document.body.classList.add(`t-${pageTheme}`);
    }
    
    // Clean up
    return () => {
      if (pageTheme) { 
        document.body.classList.remove(`t-${pageTheme}`);
      }
    };
    
  }, [pageTheme])

  return (
    <StaticQuery 
      query={`${query}`}
      render={(data) => {
        const siteTitle = data.prismic.allMaster_pages.edges[0].node.site_title || data.site.siteMetadata.title;
        const titleTemplate = data.prismic.allMaster_pages.edges[0].node.webpage_title_template || `%s | ${siteTitle}`;
        const metaDescription = description || null;
        const lang = `en`;

        return (
          <>
            <Helmet htmlAttributes={{
                lang,
                // class: pageTheme ? `t-${pageTheme}` : ``
              }}
              titleTemplate={titleTemplate}
            >
                <title>{title}</title>
                <meta name="theme-color" content="#333"></meta>
                
                <meta name="og:title" content={title} />
                
                <meta name="og:type" content={`website`} />
                <meta name="twitter:card" content={`summary`} />
                <meta name="twitter:creator" content={data.site.siteMetadata.author} />
                <meta name="twitter:title" content={title} />

                {image && <meta name="image" content={image.url} />}

                {metaDescription && (
                  <meta name="description" content={metaDescription} />
                )}

                {metaDescription && (
                  <meta property="og:description" content={metaDescription} />
                )}

                {image && <meta property="og:image" content={image.url} />}

                {metaDescription && (
                  <meta name="twitter:description" content={metaDescription} />
                )}

                {image && <meta name="twitter:image" content={image.url} />}

            </Helmet>
          </>
        )
      }} 
    />
  )
};

const query = graphql`
query {
  site {
    siteMetadata {
      title
      description
      author
    }
  }
  prismic {
    allMaster_pages {
      edges {
        node {
          site_title
          webpage_title_template
        }
      }
    }
  }
}
`

export default SEO
