import React from "react"
import Emoji from 'a11y-react-emoji'
import PageLink from './pageLink'

const Button = ({ text, link, emoji, emojiLabel, classes, ...rest }) => {

  return (
    <PageLink item={link} className={`c-button ${classes ? classes : ''}`} {...rest}>
      {emoji &&
        <Emoji symbol={emoji} label={emojiLabel} className={`c-button__symbol`} />
      }
      {text}
    </PageLink>
  )
}

export default Button
