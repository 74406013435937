import React from "react"
import { Link } from "gatsby";

const Logo = ({ hasText, logoType }) => {
  hasText = true;
  const logoWidth = hasText ? `140` : `54.54`
  
  return (
    <Link to={`/`} className={`c-logo`} tabIndex="-1" role="presentation">
      {logoType === "heart" ? (
        <svg className={`c-logo--heart`} xmlns="http://www.w3.org/2000/svg" width="56.087" height="50.359" viewBox="0 0 56.087 50.359"><path d="M45.4,6.517c-6.141-2.455-12.591-.747-17.132,4.446-4.541-5.193-10.992-6.9-17.123-4.45C4.506,9.1-1.535,16.98.892,28.146v0C4.678,45.41,24.95,54.506,27.253,55.475a2.459,2.459,0,0,0,2.336,0c2.293-.967,22.375-10.007,26.364-27.344C58.183,17.147,52.266,9.363,45.4,6.517Z" transform="translate(-0.344 -5.412)" fill="#e25b3d"/></svg>
      ) : (
        <svg className={`c-logo--classic`} xmlns="http://www.w3.org/2000/svg" width={logoWidth} height="auto" viewBox="0 0 233.519 96.796">
        {/* // <svg className={`c-logo--classic`} xmlns="http://www.w3.org/2000/svg" width={`${logoWidth}`} height="58.03" viewBox={`0 0 ${logoWidth} 58.03`}> */}
          <g id="Logo-Group_14749" data-name="Group 14749" transform="translate(-501.481 -552.219)">
              <g id="Logo-Group_14759" data-name="Group 14759">
                  <g id="Logo-Group_14749-2" data-name="Group 14749"><path id="Logo-Rectangle_2994" data-name="Rectangle 2994" className="logo-cls-1" transform="translate(535.803 586.541)" d="M0 0h22.322v22.322H0z" /></g>
                  <g id="Logo-Group_14750" data-name="Group 14750">
                      <path id="Logo-Path_25129" data-name="Path 25129" className="logo-cls-1" d="M501.481 582.541h.983l-.983-.983z" />
                      <path id="Logo-Path_25130" data-name="Path 25130" className="logo-cls-1" d="M501.481 576.6l5.943 5.943h1.769l-7.711-7.711z" />
                      <path id="Logo-Path_25131" data-name="Path 25131" className="logo-cls-1" d="M501.481 569.87l12.671 12.671h1.769l-14.44-14.44z" />
                      <path id="Logo-Path_25132" data-name="Path 25132" className="logo-cls-1" d="M501.481 563.141l19.4 19.4h1.769l-21.168-21.168z" />
                      <path id="Logo-Path_25133" data-name="Path 25133" className="logo-cls-1" d="M501.481 556.413l26.128 26.128h1.769l-27.9-27.9z" />
                      <path id="Logo-Path_25134" data-name="Path 25134" className="logo-cls-1" d="M504.016 552.219l27.784 27.787v-1.769l-26.018-26.018z" />
                      <path id="Logo-Path_25135" data-name="Path 25135" className="logo-cls-1" d="M510.745 552.219l21.055 21.059v-1.769l-19.29-19.29z" />
                      <path id="Logo-Path_25136" data-name="Path 25136" className="logo-cls-1" d="M517.473 552.219l14.33 14.33v-1.769l-12.561-12.561z" />
                      <path id="Logo-Path_25137" data-name="Path 25137" className="logo-cls-1" d="M524.2 552.219l7.6 7.6v-1.769l-5.833-5.833z" />
                      <path id="Logo-Path_25138" data-name="Path 25138" className="logo-cls-1" d="M531.8 553.092v-.873h-.873z" />
                  </g>
                  <g id="Logo-Group_14752" data-name="Group 14752">
                      <g id="Logo-Group_14751" data-name="Group 14751">
                          <path id="Logo-Path_25139" data-name="Path 25139" className="logo-cls-1" d="M531.8 581.243l1.3 1.3h4.242L531.8 577z" />
                          <path id="Logo-Path_25140" data-name="Path 25140" className="logo-cls-1" d="M531.8 574.514l8.027 8.027h4.242L531.8 570.272z" />
                          <path id="Logo-Path_25141" data-name="Path 25141" className="logo-cls-1" d="M531.8 567.786l14.755 14.755h4.245l-19-19z" />
                          <path id="Logo-Path_25142" data-name="Path 25142" className="logo-cls-1" d="M531.8 561.057l21.484 21.484h4.242L531.8 556.815z" />
                          <path id="Logo-Path_25143" data-name="Path 25143" className="logo-cls-1" d="M531.8 552.219v2.109l28.212 28.212h2.109v-2.133l-28.189-28.189z" />
                          <path id="Logo-Path_25144" data-name="Path 25144" className="logo-cls-1" d="M536.422 552.219l25.7 25.7v-4.239l-21.46-21.46z" />
                          <path id="Logo-Path_25145" data-name="Path 25145" className="logo-cls-1" d="M543.151 552.219l18.974 18.974v-4.242l-14.732-14.732z" />
                          <path id="Logo-Path_25146" data-name="Path 25146" className="logo-cls-1" d="M549.879 552.219l12.246 12.246v-4.242l-8-8z" />
                          <path id="Logo-Path_25147" data-name="Path 25147" className="logo-cls-1" d="M556.608 552.219l5.517 5.517v-4.242l-1.275-1.275z" />
                      </g>
                  </g>
                  <g id="Logo-Group_14753" data-name="Group 14753">
                      <path id="Logo-Path_25148" data-name="Path 25148" className="logo-cls-1" d="M562.125 582.541h1.426l-1.426-1.426z" />
                      <path id="Logo-Path_25149" data-name="Path 25149" className="logo-cls-1" d="M562.125 577.215l5.326 5.326h2.828l-8.154-8.154z" />
                      <path id="Logo-Path_25150" data-name="Path 25150" className="logo-cls-1" d="M562.125 570.487l12.055 12.055h2.828l-14.883-14.883z" />
                      <path id="Logo-Path_25151" data-name="Path 25151" className="logo-cls-1" d="M562.125 563.758l18.783 18.783h2.828l-21.611-21.611z" />
                      <path id="Logo-Path_25152" data-name="Path 25152" className="logo-cls-1" d="M562.125 557.029l25.512 25.512h2.828l-28.34-28.34z" />
                      <path id="Logo-Path_25153" data-name="Path 25153" className="logo-cls-1" d="M564.043 552.219l28.4 28.4v-2.828l-25.575-25.575z" />
                      <path id="Logo-Path_25154" data-name="Path 25154" className="logo-cls-1" d="M570.772 552.219l21.675 21.675v-2.828L573.6 552.219z" />
                      <path id="Logo-Path_25155" data-name="Path 25155" className="logo-cls-1" d="M577.5 552.219l14.946 14.946v-2.828l-12.118-12.118z" />
                      <path id="Logo-Path_25156" data-name="Path 25156" className="logo-cls-1" d="M584.229 552.219l8.218 8.218v-2.828l-5.39-5.39z" />
                      <path id="Logo-Path_25157" data-name="Path 25157" className="logo-cls-1" d="M592.446 553.709v-1.489h-1.489z" />
                  </g>
                  <g id="Logo-Group_14754" data-name="Group 14754">
                      <path id="Logo-Path_25158" data-name="Path 25158" className="logo-cls-1" d="M562.125 612.272l.591.591h5.656l-6.248-6.248z" />
                      <path id="Logo-Path_25159" data-name="Path 25159" className="logo-cls-1" d="M562.125 605.543l7.32 7.32h5.655l-12.976-12.976z" />
                      <path id="Logo-Path_25160" data-name="Path 25160" className="logo-cls-1" d="M562.125 598.815l14.048 14.048h5.656l-19.7-19.7z" />
                      <path id="Logo-Path_25161" data-name="Path 25161" className="logo-cls-1" d="M562.125 592.086l20.775 20.777h5.656l-26.431-26.433z" />
                      <path id="Logo-Path_25162" data-name="Path 25162" className="logo-cls-1" d="M562.125 582.541v2.816l27.505 27.505h2.816v-2.84l-27.482-27.482z" />
                      <path id="Logo-Path_25163" data-name="Path 25163" className="logo-cls-1" d="M566.037 582.541l26.41 26.41v-5.656l-20.753-20.753z" />
                      <path id="Logo-Path_25164" data-name="Path 25164" className="logo-cls-1" d="M572.765 582.541l19.681 19.681v-5.656l-14.025-14.025z" />
                      <path id="Logo-Path_25165" data-name="Path 25165" className="logo-cls-1" d="M579.494 582.541l12.953 12.953v-5.656l-7.3-7.3z" />
                      <path id="Logo-Path_25166" data-name="Path 25166" className="logo-cls-1" d="M586.222 582.541l6.224 6.224v-5.656l-.568-.568z" />
                  </g>
                  <g id="Logo-Group_14755" data-name="Group 14755">
                      <path id="Logo-Path_25167" data-name="Path 25167" className="logo-cls-1" d="M501.481 643.185h2.306l-2.306-2.306z" />
                      <path id="Logo-Path_25168" data-name="Path 25168" className="logo-cls-1" d="M501.481 638.392l4.793 4.793h4.242l-9.035-9.035z" />
                      <path id="Logo-Path_25169" data-name="Path 25169" className="logo-cls-1" d="M501.481 631.663L513 643.185h4.242l-15.763-15.763z" />
                      <path id="Logo-Path_25170" data-name="Path 25170" className="logo-cls-1" d="M501.481 624.935l18.25 18.25h4.242l-22.492-22.492z" />
                      <path id="Logo-Path_25171" data-name="Path 25171" className="logo-cls-1" d="M501.481 618.206l24.978 24.978h4.241l-29.22-29.221z" />
                      <path id="Logo-Path_25172" data-name="Path 25172" className="logo-cls-1" d="M502.866 612.863L531.8 641.8v-4.242l-24.694-24.694z" />
                      <path id="Logo-Path_25173" data-name="Path 25173" className="logo-cls-1" d="M509.595 612.863l22.205 22.208v-4.242l-17.966-17.966z" />
                      <path id="Logo-Path_25174" data-name="Path 25174" className="logo-cls-1" d="M516.323 612.863l15.48 15.48V624.1l-11.237-11.237z" />
                      <path id="Logo-Path_25175" data-name="Path 25175" className="logo-cls-1" d="M523.052 612.863l8.751 8.751v-4.242l-4.509-4.509z" />
                      <path id="Logo-Path_25176" data-name="Path 25176" className="logo-cls-1" d="M531.8 614.885v-2.023h-2.02z" />
                  </g>
                  <g id="Logo-Group_14756" data-name="Group 14756">
                      <path id="Logo-Path_25177" data-name="Path 25177" className="logo-cls-1" d="M562.125 643.185h1.513l-1.513-1.513z" />
                      <path id="Logo-Path_25178" data-name="Path 25178" className="logo-cls-1" d="M562.125 637.772l5.413 5.413h2.828l-8.241-8.241z" />
                      <path id="Logo-Path_25179" data-name="Path 25179" className="logo-cls-1" d="M562.125 631.043l12.142 12.142h2.828l-14.97-14.97z" />
                      <path id="Logo-Path_25180" data-name="Path 25180" className="logo-cls-1" d="M562.125 624.315l18.87 18.87h2.828l-21.7-21.7z" />
                      <path id="Logo-Path_25181" data-name="Path 25181" className="logo-cls-1" d="M562.125 617.586l25.6 25.6h2.828l-28.427-28.427z" />
                      <path id="Logo-Path_25182" data-name="Path 25182" className="logo-cls-1" d="M564.13 612.863l28.316 28.316v-2.828l-25.488-25.488z" />
                      <path id="Logo-Path_25183" data-name="Path 25183" className="logo-cls-1" d="M570.858 612.863l21.588 21.588v-2.828l-18.76-18.76z" />
                      <path id="Logo-Path_25184" data-name="Path 25184" className="logo-cls-1" d="M577.587 612.863l14.859 14.859v-2.828l-12.031-12.031z" />
                      <path id="Logo-Path_25185" data-name="Path 25185" className="logo-cls-1" d="M584.315 612.863l8.131 8.131v-2.828l-5.3-5.3z" />
                      <path id="Logo-Path_25186" data-name="Path 25186" className="logo-cls-1" d="M592.446 614.265v-1.4h-1.4z" />
                  </g>
                  <g id="Logo-Group_14757" data-name="Group 14757">
                      <path id="Logo-Path_25187" data-name="Path 25187" className="logo-cls-1" d="M531.8 640.032l3.153 3.153h.707l-3.86-3.86z" />
                      <path id="Logo-Path_25188" data-name="Path 25188" className="logo-cls-1" d="M531.8 633.3l9.881 9.881h.707L531.8 632.6z" />
                      <path id="Logo-Path_25189" data-name="Path 25189" className="logo-cls-1" d="M531.8 626.575l16.61 16.61h.707L531.8 625.868z" />
                      <path id="Logo-Path_25190" data-name="Path 25190" className="logo-cls-1" d="M531.8 619.846l23.338 23.338h.707L531.8 619.139z" />
                      <path id="Logo-Path_25191" data-name="Path 25191" className="logo-cls-1" d="M531.8 612.863v.255l30.067 30.067h.255v-.452l-29.87-29.87z" />
                      <path id="Logo-Path_25192" data-name="Path 25192" className="logo-cls-1" d="M538.276 612.863l23.848 23.848V636l-23.141-23.141z" />
                      <path id="Logo-Path_25193" data-name="Path 25193" className="logo-cls-1" d="M545 612.863l17.12 17.12v-.707l-16.413-16.413z" />
                      <path id="Logo-Path_25194" data-name="Path 25194" className="logo-cls-1" d="M551.733 612.863l10.391 10.391v-.707l-9.684-9.684z" />
                      <path id="Logo-Path_25195" data-name="Path 25195" className="logo-cls-1" d="M558.462 612.863l3.663 3.663v-.707l-2.956-2.956z" />
                  </g>
                  <g id="Logo-Group_14758" data-name="Group 14758">
                      <path id="Logo-Path_25196" data-name="Path 25196" className="logo-cls-1" d="M501.481 609.71l3.153 3.153h.707l-3.86-3.86z" />
                      <path id="Logo-Path_25197" data-name="Path 25197" className="logo-cls-1" d="M501.481 602.982l9.881 9.881h.707l-10.588-10.588z" />
                      <path id="Logo-Path_25198" data-name="Path 25198" className="logo-cls-1" d="M501.481 596.253l16.61 16.61h.707l-17.317-17.317z" />
                      <path id="Logo-Path_25199" data-name="Path 25199" className="logo-cls-1" d="M501.481 589.525l23.338 23.338h.707l-24.045-24.045z" />
                      <path id="Logo-Path_25200" data-name="Path 25200" className="logo-cls-1" d="M501.481 582.541v.255l30.067 30.067h.255v-.452l-29.87-29.87z" />
                      <path id="Logo-Path_25201" data-name="Path 25201" className="logo-cls-1" d="M507.955 582.541l23.845 23.848v-.707l-23.141-23.141z" />
                      <path id="Logo-Path_25202" data-name="Path 25202" className="logo-cls-1" d="M514.683 582.541l17.12 17.12v-.707l-16.413-16.413z" />
                      <path id="Logo-Path_25203" data-name="Path 25203" className="logo-cls-1" d="M521.412 582.541l10.388 10.391v-.707l-9.684-9.684z" />
                      <path id="Logo-Path_25204" data-name="Path 25204" className="logo-cls-1" d="M528.14 582.541l3.66 3.659v-.7l-2.956-2.956z" />
                  </g>
              </g>
              {hasText &&
                <g id="Logo-Group_14760" data-name="Group 14760">
                    <path
                        id="Logo-Path_25205"
                        data-name="Path 25205"
                        className="logo-cls-1"
                        d="M614.845 553.282l3.206-.158q4.008-.2 5.4-.2a14.311 14.311 0 016.768 1.322 8.578 8.578 0 013.614 3.585 11.1 11.1 0 011.186 5.224 11.367 11.367 0 01-.646 3.916 8.253 8.253 0 01-1.776 2.946 7.606 7.606 0 01-2.615 1.782 10.617 10.617 0 01-2.824.762 36 36 0 01-3.765.15h-8.637v-.661h.805a3.388 3.388 0 001.732-.315 1.472 1.472 0 00.618-.985 20.972 20.972 0 00.137-3.111v-9.4a20.1 20.1 0 00-.137-3.011 1.3 1.3 0 00-.575-.933 4.59 4.59 0 00-1.919-.252h-.575zm6.007.4V566.7q0 2.889.108 3.722a1.517 1.517 0 00.646 1.171 4.962 4.962 0 002.279.338 10.751 10.751 0 004.181-.7 5.754 5.754 0 002.737-2.745 10.968 10.968 0 001.1-5.223 14.029 14.029 0 00-.64-4.413 7.949 7.949 0 00-1.832-3.153 6.2 6.2 0 00-2.643-1.653 13.162 13.162 0 00-3.722-.439q-1.092.007-2.214.079z"
                    />
                    <path
                        id="Logo-Path_25206"
                        data-name="Path 25206"
                        className="logo-cls-1"
                        d="M636.705 553.785v-.661h8.909v.661h-.414a4.742 4.742 0 00-1.882.237 1.252 1.252 0 00-.6.884 18.283 18.283 0 00-.15 3.075v9.557a26.045 26.045 0 00.106 3.01 1.539 1.539 0 00.576 1.057 3.245 3.245 0 001.789.344h.575v.661h-8.909v-.661h.573a3.382 3.382 0 001.732-.315 1.444 1.444 0 00.611-.985 22 22 0 00.13-3.111v-9.557a20.884 20.884 0 00-.13-3 1.287 1.287 0 00-.575-.935 4.542 4.542 0 00-1.925-.258z"
                    />
                    <path
                        id="Logo-Path_25207"
                        data-name="Path 25207"
                        className="logo-cls-1"
                        d="M659.941 564.29h8.407v.69a4.293 4.293 0 00-1.889.28 1.387 1.387 0 00-.655.934 18.494 18.494 0 00-.157 3.169v2.069a3.839 3.839 0 00-.618-.057 8.742 8.742 0 00-2.9.675 12.93 12.93 0 01-4.528.92 9.445 9.445 0 01-9.728-9.729 11.041 11.041 0 012.644-7.329 8.888 8.888 0 017.185-3.147 8.284 8.284 0 016.467 2.873 6.83 6.83 0 00.6-2.169h.69v6.51h-.69a7.762 7.762 0 00-2.279-4.757 6.345 6.345 0 00-4.519-1.753 6.555 6.555 0 00-4.786 2.04q-2.039 2.042-2.04 7.243t1.969 7.351a6.337 6.337 0 004.886 2.162 5.994 5.994 0 002.717-.61 3.549 3.549 0 001.666-1.488 4.863 4.863 0 00.447-2.213 6.722 6.722 0 00-.209-2.09 1.192 1.192 0 00-.639-.705 4.682 4.682 0 00-1.581-.179h-.461z"
                    />
                    <path
                        id="Logo-Path_25208"
                        data-name="Path 25208"
                        className="logo-cls-1"
                        d="M669.815 553.785v-.661h8.909v.661h-.417a4.746 4.746 0 00-1.882.237 1.252 1.252 0 00-.6.884 18.283 18.283 0 00-.15 3.075v9.557a25.733 25.733 0 00.107 3.01 1.534 1.534 0 00.575 1.057 3.245 3.245 0 001.789.344h.575v.661h-8.909v-.661h.574a3.387 3.387 0 001.732-.315 1.446 1.446 0 00.61-.985 21.841 21.841 0 00.13-3.111v-9.557a20.733 20.733 0 00-.13-3 1.287 1.287 0 00-.575-.935 4.542 4.542 0 00-1.925-.258z"
                    />
                    <path
                        id="Logo-Path_25209"
                        data-name="Path 25209"
                        className="logo-cls-1"
                        d="M680.879 557.809h-.66l.1-4.685h18.912l.1 4.685h-.619a7.44 7.44 0 00-.906-2.781 2.377 2.377 0 00-1.235-1.049 9.044 9.044 0 00-2.255-.194h-3.133v13.753a25.97 25.97 0 00.107 3 1.528 1.528 0 00.583 1.064 3.242 3.242 0 001.781.344h.949v.661h-9.713v-.661h1.01a3.385 3.385 0 001.732-.315 1.468 1.468 0 00.617-.985 20.692 20.692 0 00.137-3.111v-13.75h-2.889a11.374 11.374 0 00-2.529.18 2.268 2.268 0 00-1.214 1.085 7.7 7.7 0 00-.875 2.759z"
                    />
                    <path
                        id="Logo-Path_25210"
                        data-name="Path 25210"
                        className="logo-cls-1"
                        d="M710.239 565.425h-7.4l-.5 1.236a12.544 12.544 0 00-1.121 3.506 1.567 1.567 0 00.595 1.33 2.464 2.464 0 001.531.452v.661h-5.792v-.661a3.12 3.12 0 001.927-.912 11.131 11.131 0 001.523-3q.07-.216.69-1.667l5.663-13.523h.56l6.509 15.477.891 2.04a2.717 2.717 0 00.812 1.085 2.954 2.954 0 001.531.5v.661H710v-.661a5.71 5.71 0 001.9-.208.808.808 0 00.5-.826 8.445 8.445 0 00-.662-1.883zm-.243-.646l-3.449-8.307-3.436 8.307z"
                    />
                    <path
                        id="Logo-Path_25211"
                        data-name="Path 25211"
                        className="logo-cls-1"
                        d="M735 566.876l-.144 5.734H718.3v-.661h.575a3.381 3.381 0 001.731-.315 1.47 1.47 0 00.619-.985 21.092 21.092 0 00.135-3.111v-9.557a20.023 20.023 0 00-.135-3 1.324 1.324 0 00-.575-.935 4.5 4.5 0 00-1.919-.258h-.431v-.661h9.355v.661h-.862a4.746 4.746 0 00-1.882.237 1.255 1.255 0 00-.6.884 18.36 18.36 0 00-.151 3.075v10.2a17.347 17.347 0 00.115 2.643 1.229 1.229 0 00.626.827 5.17 5.17 0 002.191.294h1.48a8.371 8.371 0 002.666-.315 3.73 3.73 0 001.652-1.381 10.76 10.76 0 001.43-3.377z"
                    />
                    <path
                        id="Logo-Path_25212"
                        data-name="Path 25212"
                        className="logo-cls-1"
                        d="M620.925 598.059v4.095a26.066 26.066 0 00.106 3.011 1.523 1.523 0 00.584 1.056 3.242 3.242 0 001.781.344h.646v.662h-8.924v-.662h.517a3.372 3.372 0 001.732-.315 1.444 1.444 0 00.611-.985 21.993 21.993 0 00.13-3.111V592.6a20.9 20.9 0 00-.13-3 1.285 1.285 0 00-.575-.934 4.522 4.522 0 00-1.925-.259h-.36v-.661l2.444-.086q2.931-.1 4.569-.1a17.514 17.514 0 014.65.475 5.439 5.439 0 012.672 1.681 4.5 4.5 0 011.042 3.047 5.485 5.485 0 01-.712 2.816 4.561 4.561 0 01-2.04 1.854 12.013 12.013 0 01-4.677.633zm0-.719h1.393a8.976 8.976 0 002.716-.338 3.239 3.239 0 001.725-1.416 4.83 4.83 0 00.69-2.658 4.7 4.7 0 00-1.186-3.543 5.458 5.458 0 00-3.845-1.128 14.584 14.584 0 00-1.493.058z"
                    />
                    <path
                        id="Logo-Path_25213"
                        data-name="Path 25213"
                        className="logo-cls-1"
                        d="M646.26 587.74h6.51v.661a3.533 3.533 0 00-1.934.582 2 2 0 00-.783 1.114 12.792 12.792 0 00-.144 2.4v6.481a25.574 25.574 0 01-.193 3.693 5.132 5.132 0 01-1.121 2.371 6.294 6.294 0 01-2.608 1.854 10.162 10.162 0 01-3.881.69 9.954 9.954 0 01-4.405-.884 5.615 5.615 0 01-2.571-2.285 7.821 7.821 0 01-.769-3.758l.015-2V592.6a19.933 19.933 0 00-.137-3 1.322 1.322 0 00-.575-.934 4.467 4.467 0 00-1.919-.259h-.445v-.661h8.9v.661h-.388a4.745 4.745 0 00-1.882.237 1.253 1.253 0 00-.6.884 18.207 18.207 0 00-.152 3.075v6.137a24 24 0 00.294 4.526 3.969 3.969 0 001.661 2.335 6.017 6.017 0 003.636 1 7.678 7.678 0 003.24-.668 5.283 5.283 0 002.171-1.667 4.746 4.746 0 00.869-2.055 24.81 24.81 0 00.158-3.327v-6.639a9.7 9.7 0 00-.187-2.314 1.784 1.784 0 00-.8-1.049 4.317 4.317 0 00-1.954-.474z"
                    />
                    <path
                        id="Logo-Path_25214"
                        data-name="Path 25214"
                        className="logo-cls-1"
                        d="M663.648 596.736a8.294 8.294 0 014.929 1.653 4.482 4.482 0 011.61 3.52 4.831 4.831 0 01-1.092 3.169 5.052 5.052 0 01-2.795 1.739 20.284 20.284 0 01-4.563.41h-7.645v-.662h.746a3.379 3.379 0 001.733-.315 1.446 1.446 0 00.61-.985 21.993 21.993 0 00.13-3.111v-9.485a20.577 20.577 0 00-.137-3.061 1.325 1.325 0 00-.575-.948 4.465 4.465 0 00-1.918-.259h-.374v-.661l3.206-.086 3.648-.115q7.992 0 7.991 4.6a3.776 3.776 0 01-1.372 3.047 8.24 8.24 0 01-4.132 1.55zm-3.52-.273c.239.01.439.014.6.014q3.1 0 4.239-.977a3.967 3.967 0 001.135-3.161 5.1 5.1 0 00-.445-2.357 3.019 3.019 0 00-1.338-1.286 7.286 7.286 0 00-3.047-.453q-.559 0-1.148.043zm0 .7v4.34q0 2.773.1 3.521a1.768 1.768 0 00.539 1.136 2.744 2.744 0 001.732.387 4.611 4.611 0 003.448-1.178 4.872 4.872 0 001.15-3.535 4.493 4.493 0 00-1.3-3.557q-1.3-1.143-4.577-1.143-.52.004-1.092.033z"
                    />
                    <path
                        id="Logo-Path_25215"
                        data-name="Path 25215"
                        className="logo-cls-1"
                        d="M689.026 601.492l-.142 5.735h-16.557v-.662h.575a3.372 3.372 0 001.732-.315 1.465 1.465 0 00.618-.985 20.684 20.684 0 00.137-3.111V592.6a19.652 19.652 0 00-.137-3 1.312 1.312 0 00-.574-.934 4.473 4.473 0 00-1.919-.259h-.432v-.661h9.356v.661h-.862a4.741 4.741 0 00-1.882.237 1.254 1.254 0 00-.6.884 18.294 18.294 0 00-.15 3.075v10.2a17.364 17.364 0 00.115 2.644 1.224 1.224 0 00.624.826 5.167 5.167 0 002.192.294h1.48a8.346 8.346 0 002.666-.315 3.742 3.742 0 001.653-1.38 10.818 10.818 0 001.429-3.378z"
                    />
                    <path
                        id="Logo-Path_25216"
                        data-name="Path 25216"
                        className="logo-cls-1"
                        d="M690.033 588.4v-.661h8.91v.661h-.419a4.735 4.735 0 00-1.881.237 1.254 1.254 0 00-.6.884 18.354 18.354 0 00-.151 3.075v9.557a25.353 25.353 0 00.109 3.011 1.532 1.532 0 00.573 1.056 3.255 3.255 0 001.791.344h.575v.662h-8.91v-.662h.575a3.38 3.38 0 001.732-.315 1.446 1.446 0 00.61-.985 21.894 21.894 0 00.129-3.111V592.6a20.8 20.8 0 00-.129-3 1.285 1.285 0 00-.575-.934 4.525 4.525 0 00-1.926-.259z"
                    />
                    <path
                        id="Logo-Path_25217"
                        data-name="Path 25217"
                        className="logo-cls-1"
                        d="M717.7 599.912h.619v6.826h-.619a5.232 5.232 0 00-.934-2.055 8.382 8.382 0 01-6.567 2.9 9.363 9.363 0 01-6.906-2.673 9.542 9.542 0 01-2.679-7.07 12.315 12.315 0 011.236-5.44 8.352 8.352 0 013.449-3.787 9.8 9.8 0 014.857-1.235 8.642 8.642 0 013.959.9 7.939 7.939 0 012.651 1.99 7.106 7.106 0 00.748-2.529h.632l.173 7.386h-.619q-.645-3.936-2.637-5.474a7.151 7.151 0 00-4.49-1.539 5.705 5.705 0 00-4.873 2.488q-1.8 2.486-1.8 7.113 0 4.383 1.968 6.718a6.157 6.157 0 004.9 2.336 7.565 7.565 0 003.205-.762 5.581 5.581 0 002.594-2.579 9.661 9.661 0 001.133-3.514z"
                    />
                    <path
                        id="Logo-Path_25218"
                        data-name="Path 25218"
                        className="logo-cls-1"
                        d="M615.852 641.642v-6.265h.719q1.219 6.121 5.79 6.122a4.211 4.211 0 003.183-1.172 3.691 3.691 0 001.129-2.622 3.333 3.333 0 00-.992-2.457 12.277 12.277 0 00-3.621-2.1 26.514 26.514 0 01-3.536-1.76 5.363 5.363 0 01-1.86-1.962 5.209 5.209 0 01-.669-2.572 4.607 4.607 0 011.445-3.47A5.064 5.064 0 01621.1 622a6.693 6.693 0 012.328.4 5.97 5.97 0 012.241 1.667 3.083 3.083 0 00.748-1.71h.632v5.1h-.632a6.837 6.837 0 00-1.934-3.507 4.588 4.588 0 00-3.21-1.279 3.193 3.193 0 00-2.551.941 3.089 3.089 0 00-.812 2.063 2.975 2.975 0 001.02 2.284 13.879 13.879 0 003.635 2.127 37.731 37.731 0 013.657 1.8 5.1 5.1 0 011.834 1.89 5.536 5.536 0 01.688 2.788 5.327 5.327 0 01-1.666 4.067 6.243 6.243 0 01-4.455 1.566 6.8 6.8 0 01-5.346-2.458 4.875 4.875 0 00-.7 1.9z"
                    />
                    <path
                        id="Logo-Path_25219"
                        data-name="Path 25219"
                        className="logo-cls-1"
                        d="M643.158 642.131a13.743 13.743 0 01-1.452.072 10.732 10.732 0 01-7.667-2.795 9.734 9.734 0 01-2.967-7.437 9.31 9.31 0 013.011-7.236A10.777 10.777 0 01641.62 622a12.6 12.6 0 015.389 1.214 8.5 8.5 0 013.937 3.593 10.566 10.566 0 011.336 5.267 10.382 10.382 0 01-1.746 5.949 9.161 9.161 0 01-5.051 3.621l-4.3 2.372c.3-.029.554-.043.776-.043a22.815 22.815 0 017.343 1.437 16.547 16.547 0 005.058 1.106q2.587 0 3.075-1.954h.6a4.925 4.925 0 01-1.652 3.219 5.314 5.314 0 01-3.636 1.236 7.18 7.18 0 01-2.666-.475 20.036 20.036 0 01-3.125-1.7q-3.449-2.2-5.748-2.2a4.11 4.11 0 00-2.673 1.092 2.65 2.65 0 01-1.194.719c-.344 0-.516-.159-.516-.474q0-.36.617-.741t2.328-1.214c.24-.115.479-.239.719-.374zm-8.8-10.059q0 4.7 1.876 7.056a6.756 6.756 0 005.626 2.356 6.338 6.338 0 005.2-2.356q1.913-2.356 1.912-7.028 0-4.54-1.883-6.962a7.353 7.353 0 00-10.856-.058q-1.87 2.364-1.871 6.992z"
                    />
                    <path
                        id="Logo-Path_25220"
                        data-name="Path 25220"
                        className="logo-cls-1"
                        d="M667.646 622.357h6.509v.661a3.532 3.532 0 00-1.933.582 2 2 0 00-.783 1.114 12.79 12.79 0 00-.144 2.4v6.481a25.566 25.566 0 01-.193 3.693 5.137 5.137 0 01-1.121 2.371 6.3 6.3 0 01-2.609 1.854 10.157 10.157 0 01-3.88.69 9.947 9.947 0 01-4.405-.884 5.615 5.615 0 01-2.571-2.285 7.809 7.809 0 01-.77-3.758l.015-2v-6.065a19.787 19.787 0 00-.137-3 1.316 1.316 0 00-.575-.934 4.462 4.462 0 00-1.918-.259h-.445v-.661h8.894v.661h-.387a4.75 4.75 0 00-1.883.237 1.25 1.25 0 00-.595.884 18.08 18.08 0 00-.152 3.075v6.136a23.92 23.92 0 00.294 4.527 3.96 3.96 0 001.661 2.335 6.016 6.016 0 003.635 1 7.679 7.679 0 003.241-.668 5.286 5.286 0 002.17-1.667 4.753 4.753 0 00.87-2.055 25.032 25.032 0 00.157-3.327v-6.639a9.709 9.709 0 00-.186-2.314 1.788 1.788 0 00-.805-1.049 4.316 4.316 0 00-1.954-.474z"
                    />
                    <path
                        id="Logo-Path_25221"
                        data-name="Path 25221"
                        className="logo-cls-1"
                        d="M685.58 634.658h-7.4l-.5 1.236a12.576 12.576 0 00-1.12 3.506 1.568 1.568 0 00.6 1.33 2.46 2.46 0 001.531.452v.662h-5.801v-.662a3.12 3.12 0 001.927-.912 11.228 11.228 0 001.522-3q.072-.216.69-1.667l5.662-13.523h.561l6.509 15.477.892 2.041a2.706 2.706 0 00.812 1.085 2.959 2.959 0 001.53.495v.662h-7.659v-.662a5.706 5.706 0 001.9-.208.806.806 0 00.5-.826 8.356 8.356 0 00-.661-1.883zm-.244-.646l-3.449-8.306-3.435 8.306z"
                    />
                    <path
                        id="Logo-Path_25222"
                        data-name="Path 25222"
                        className="logo-cls-1"
                        d="M714.207 641.844h-5.247a23.477 23.477 0 01-4.266-5.116q-.919-1.322-1.911-2.537a10.048 10.048 0 00-1.367-1.48 2.032 2.032 0 00-1.164-.266h-.775v4.326a25.985 25.985 0 00.107 3.011 1.527 1.527 0 00.575 1.056 3.243 3.243 0 001.788.344h.934v.662h-9.324v-.662h.631a3.372 3.372 0 001.732-.315 1.444 1.444 0 00.611-.985 22.112 22.112 0 00.128-3.111v-9.557a21.008 21.008 0 00-.128-3 1.285 1.285 0 00-.575-.934 4.526 4.526 0 00-1.925-.259h-.331v-.661l2.788-.144q2.789-.129 4.428-.129a16.351 16.351 0 014.532.518 6.041 6.041 0 012.823 1.7 4.022 4.022 0 011.079 2.817 4.353 4.353 0 01-1.407 3.262 7.047 7.047 0 01-4.141 1.681q1.224.59 3.938 4.254a19.768 19.768 0 003.478 3.894 5.24 5.24 0 002.99.977zm-14.73-10.06q1.077-.028 2.227-.086a8.351 8.351 0 002-.316 3.132 3.132 0 002.2-2.055 5.715 5.715 0 00.3-1.983 4.982 4.982 0 00-1.027-3.291q-1.031-1.236-4.477-1.236c-.356 0-.762.019-1.222.057z"
                    />
                    <path
                        id="Logo-Path_25223"
                        data-name="Path 25223"
                        className="logo-cls-1"
                        d="M731.062 635.779h.69l-.416 6.065H715.2v-.662h.4a3.028 3.028 0 001.876-.387 1.749 1.749 0 00.538-.985 22.877 22.877 0 00.115-3.039v-9.557a21.755 21.755 0 00-.122-2.931 1.377 1.377 0 00-.56-.985 3.955 3.955 0 00-1.847-.28v-.661h13.925l.46 4.857h-.675a7.616 7.616 0 00-1.129-2.9 2.853 2.853 0 00-1.588-1.078 13.887 13.887 0 00-2.96-.223h-2.7v8.187H723a5.6 5.6 0 001.991-.244 1.285 1.285 0 00.748-.741 6.75 6.75 0 00.179-1.932h.675v6.668h-.675a5.732 5.732 0 00-.272-2.177 1.324 1.324 0 00-.8-.719 6.612 6.612 0 00-1.774-.165h-2.142v4.61q0 3.464.445 4.074t2.4.61h1.451a6.882 6.882 0 002.529-.4 4.528 4.528 0 001.891-1.624 9.482 9.482 0 001.416-3.381z"
                    />
                </g>
              }
          </g>
        </svg>
      )}
      
      <span className="show-for-sr">Go to Home - Digital Public Square - Not for Profit</span>
    </Link>
  )
}

export default Logo
