import React from "react"

import Button from "./button"
import Logo from "./logo"

const Footer = ({ doc, navOpen }) => (
  <footer inert={navOpen ? "" : null} className={`c-footer`}>
    <div className={`grid-x grid-margin-x grid-padding-x align-middle`}>
      <div className={`c-footer__logo cell`}>
        <Logo hasText={true} />
      </div>
      <div className={`c-footer__nav cell`}>
        <Button
          text={doc.node.contact_button_text}
          link={doc.node.contact_button_link}
          emoji={doc.node.contact_button_emoji}
          emojiLabel={doc.node.contact_button_emoji_label}
          classes={`c-button--text`}
        />
        <Button
          text={doc.node.land_ack_text}
          link={doc.node.land_ack_link}
          classes={`c-button--text`}
        />

        <Button
          text={doc.node.support_button_text}
          link={doc.node.support_button}
          emoji={doc.node.support_button_emoji}
          emojiLabel={doc.node.support_button_emoji_label}
          classes={`c-button--text`}
        />
      </div>
      <div className={`c-footer__content cell small-12 medium-auto`}>
        © {new Date().getFullYear()} {doc.node.site_title}
      </div>
    </div>
  </footer>
)

export default Footer
