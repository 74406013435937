import React from "react"
import { Link } from 'gatsby'
import { linkResolver } from "../../utils/linkResolver"
// import useMousePosition from "../useMousePosition";

const PageLink = ({ item, children, ...rest }) => {

  if (!item) return null;

  if (item._linkType === "Link.web" || item._linkType == null) {
    return (
      <a href={item.url} {...rest}>
        {children}
      </a>
    )
  } else {
    return (
      // <Link onMouseEnter={(e) => handleMouseEnter(e)} onMouseLeave={(e) => handleMouseLeave(e)} to={linkResolver(item._meta)} {...rest}>
      <Link to={linkResolver(item._meta)} {...rest}>
        {children}
      </Link>
    )
  }
}

export default PageLink
