import React, { useRef, useEffect } from "react"

import PageLink from "./pageLink"
import Button from "./button"
import Logo from "./logo"
import useWindowSize from "../hooks/useWindowSize"
import useScrollPosition from "../hooks/useScrollPosition"

const Header = ({
  doc,
  navOpen,
  toggleNavState,
  logoType,
  parentPage,
  pageTitle,
}) => {
  const toggleRef = useRef(null)
  const headerBar = useRef(null)
  const { viewMax } = useWindowSize()
  const { scrollPosition } = useScrollPosition()

  pageTitle = pageTitle || "Digital Public Square"

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerBar.current.offsetHeight}px`
    )
  }, [viewMax.y])

  const checkForEsc = e => {
    if (e.keyCode === 27 && navOpen === true) {
      toggleNavState(false)
      toggleRef.current.focus()
    }
  }

  return (
    // override: jsx-a11y interactive element with key/mouse events - non-issue
    // eslint-disable-next-line
    <header
      onKeyDown={e => checkForEsc(e)}
      className={`c-header ${navOpen ? `is-open` : ``} ${
        scrollPosition.y > 100 ? `is-scrolled` : ``
      }`}
    >
      <div
        ref={headerBar}
        className={`c-header__bar grid-x grid-padding-x align-justify`}
      >
        <div className={`cell auto`}>
          <div className={`c-header__title`}>
            <Logo hasText={false} logoType={logoType} />

            {!parentPage && (
              <div className={`show-for-sr`}>
                <h1>{pageTitle}</h1>
              </div>
            )}
          </div>
        </div>
        <div className={`cell shrink`}>
          <button
            ref={toggleRef}
            onClick={() => toggleNavState(!navOpen)}
            aria-label={`Toggle site navigation`}
            className={`c-header__toggle`}
            type="button"
            aria-expanded={navOpen}
            aria-controls="primaryNav"
          >
            <div className={`c-header__toggle__icon`}></div>
          </button>
        </div>
      </div>
      <div id="primaryNav" aria-hidden={!navOpen} className="c-header__nav">
        <div className={`c-header__nav__content grid-padding-x`}>
          <nav className={`cell`} aria-label="Primary Navigation">
            <ul>
              {doc.node.header_navigation.map((item, index) => (
                <li key={index}>
                  <PageLink item={item.header_link}>
                    {item.header_link_text}
                  </PageLink>
                </li>
              ))}
            </ul>
          </nav>

          <div className="c-header__nav__utility cell flex-container align-justify">
            <div>
              <Button
                text={doc.node.contact_button_text}
                link={doc.node.contact_button_link}
                emoji={doc.node.contact_button_emoji}
                emojiLabel={doc.node.contact_button_emoji_label}
                classes={`c-button--text`}
              />
            </div>
            <Button
              text={doc.node.land_ack_text}
              link={doc.node.land_ack_link}
              classes={`c-button--text`}
            />
            <div>
              <div>
                <Button
                  text={doc.node.support_button_text}
                  link={doc.node.support_button}
                  emoji={doc.node.support_button_emoji}
                  emojiLabel={doc.node.support_button_emoji_label}
                  classes={`c-button--text`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
