import { useState, useEffect } from "react";
import debounce from "lodash.debounce";

const useWindowSize = () => {
  const [viewMax, setViewMax] = useState({ 
    x: typeof window !== 'undefined' && window.innerWidth,
    y: typeof window !== 'undefined' && window.innerHeight
  });



  useEffect(() => {
    const updateViewMax = () => {
      setViewMax({ 
        x: window.innerWidth,
        y: window.innerHeight
       });
    };

    const debouncedUpdateViewMax = debounce(updateViewMax, 500)

    window.addEventListener("resize", debouncedUpdateViewMax);

    debouncedUpdateViewMax();

    return () => window.removeEventListener("resize", debouncedUpdateViewMax);
  }, []);

  return { viewMax };
};

export default useWindowSize;