/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import { StaticQuery, graphql } from "gatsby"

import Header from "./shared/header"
import Footer from "./shared/footer"

import "../styles/app.scss"
import useWindowSize from "./hooks/useWindowSize"

const Layout = ({ pageTitle, lang, parentPage, logoType, children }) => {
  const [navOpen, setNavOpen] = useState(false)

  const { viewMax } = useWindowSize()

  let currentScrollPosition =
    (typeof window !== "undefined" && window.pageYOffset) ||
    (typeof document !== "undefined" && document.documentElement.scrollTop)

  useEffect(() => {
    let vh = viewMax.y * 0.01

    document.documentElement.style.setProperty("--vh", `${vh}px`)
    document.documentElement.setAttribute("ie-style", `--vh: ${vh}px`)
  }, [viewMax.y])

  const toggleNavState = openState => {
    setNavOpen(openState)
    if (openState === true) {
      document.documentElement.classList.add("is-locked")
      currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop
    } else if (openState === false) {
      document.documentElement.classList.remove("is-locked")
      setNavOpen(openState)
      setTimeout(() => {
        document.documentElement.scrollTop = currentScrollPosition
      }, 100)
    }
  }

  return (
    <StaticQuery
      query={`${query}`}
      render={data => {
        const doc = data.prismic.allMaster_pages.edges.slice(0, 1).pop()

        return (
          <>
            <a
              href="#content"
              inert={navOpen ? "" : null}
              className={`show-on-focus`}
            >
              Skip to main content
            </a>
            {/* <div className={`o-page-container ${navOpen ? `is-locked` : ``}`}> */}
            <Header
              pageTitle={pageTitle}
              logoType={logoType}
              toggleNavState={toggleNavState}
              navOpen={navOpen}
              parentPage={parentPage}
              doc={doc}
            />

            <main
              id={`content`}
              lang={lang !== "en" ? lang : null}
              inert={navOpen ? "" : null}
            >
              {children}
            </main>

            <Footer navOpen={navOpen} doc={doc} />
            {/* </div> */}
          </>
        )
      }}
    />
  )
}

const query = graphql`
  query MasterPageQuery {
    prismic {
      allMaster_pages {
        edges {
          node {
            site_title
            land_ack_text
            land_ack_link {
              ... on PRISMIC_Basic_page {
                _linkType
                _meta {
                  type
                  uid
                }
              }
            }
            webpage_title_template
            contact_button_text
            contact_button_emoji_label
            contact_button_emoji
            contact_button_link {
              _linkType
              ... on PRISMIC_Homepage {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Our_work {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Support {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_list {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_post {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Approach {
                _meta {
                  type
                }
              }
              ... on PRISMIC_About {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Basic_page {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Case_study {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            support_button_text
            support_button_emoji_label
            support_button_emoji
            support_button {
              _linkType
              ... on PRISMIC_Homepage {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Support {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_list {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_post {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Our_work {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Approach {
                _meta {
                  type
                }
              }
              ... on PRISMIC_About {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Basic_page {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Case_study {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            header_navigation {
              header_link {
                _linkType
                ... on PRISMIC_Homepage {
                  _meta {
                    type
                  }
                }
                ... on PRISMIC_Our_work {
                  _meta {
                    type
                  }
                }
                ... on PRISMIC_Support {
                  _meta {
                    type
                  }
                }
                ... on PRISMIC_Blog_list {
                  _meta {
                    type
                  }
                }
                ... on PRISMIC_Blog_post {
                  _linkType
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Approach {
                  _meta {
                    type
                  }
                }
                ... on PRISMIC_About {
                  _meta {
                    type
                  }
                }
                ... on PRISMIC_Basic_page {
                  _linkType
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC_Case_study {
                  _linkType
                  _meta {
                    type
                    uid
                  }
                }
                ... on PRISMIC__ExternalLink {
                  _linkType
                  url
                }
              }
              header_link_text
            }
            social_media_links {
              link_text
              platform
              link_to_profile {
                ... on PRISMIC__ExternalLink {
                  _linkType
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Layout
